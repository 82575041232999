import React from 'react';
import classNames from 'classnames';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';
import SectionHeader, { SectionHeaderTag } from '../partials/SectionHeader';

interface AboutUsProps extends SectionTilesTypesProps {
  className?: string;
}

const defaultProps: SectionTilesTypesProps = {
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
};

const AboutUs: React.FC<AboutUsProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'about-us section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  return (
    <section id="about-us-section" {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="container">
          <SectionHeader tag={SectionHeaderTag.H1} title="Every bit of the team matters." className="center-content pb-0">
            <h2 className="h4">Our ethos embodies collaboration, agility, and a commitment to perfection across all endeavors.</h2>
            <p className="mb-0">
              We're the team that actively listens, comprehensively understands client needs, and assembles disparate elements into a cohesive whole,
              leveraging diverse experience, expertise, and insights to deliver tailored solutions for your team through Atlassian tools.
            </p>
          </SectionHeader>
        </div>
      </div>
    </section>
  );
};

AboutUs.defaultProps = defaultProps;

export default AboutUs;
