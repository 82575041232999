import React from 'react';
import classNames from 'classnames';
import SectionHeader, { SectionHeaderTag } from '../partials/SectionHeader';
import Image from '../../elements/Image';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';

interface CertificatesProps extends SectionTilesTypesProps {
  className?: string;
}

const defaultProps: SectionTilesTypesProps = {
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
};

const Certificates: React.FC<CertificatesProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'certificates section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  return (
    <section id="certificates-section" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag={SectionHeaderTag.H2} title="A team of experts." className="center-content pb-0">
            <h2 className="h4">Incremental experiences contribute to the accumulation of knowledge flow.</h2>
            <p className="mb-32">
              Therefore, all team members continually enhance their skills by learning from the Atlassian ecosystem and
              related areas. See below for some of the badges earned!
            </p>
          </SectionHeader>
          <div className="d-flex justify-content-center align-items-center flex-wrap m-n12 reveal-from-bottom">
            <a
              href="https://university.atlassian.com/student/activity/840991-acp-600-project-administration-in-jira-server-certification"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={require('../../../assets/images/certificates/AC-JPA.svg')}
                alt="Atlassian Certified in Jira Project Administration"
                className="m-12"
                width={97}
                height={97}
              />
            </a>

            <a
              href="https://university.atlassian.com/student/activity/837225-acp-100-jira-administration-for-data-center-and-server-exam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={require('../../../assets/images/certificates/ACP-JA.svg')}
                alt="Atlassian Certified Jira Administrator"
                className="m-12"
                width={97}
                height={97}
              />
            </a>

            <Image
              src={require('../../../assets/images/certificates/ACP-JSD.svg')}
              alt="Atlassian Certified Jira Service Desk Administrator"
              className="m-12"
              width={97}
              height={97}
            />

            <Image
              src={require('../../../assets/images/certificates/ACP-CA.svg')}
              alt="Atlassian Certified Confluence Administrator"
              className="m-12"
              width={97}
              height={97}
            />

            <a
              href="https://university.atlassian.com/student/activity/832603-acp-120-jira-administration-for-cloud-exam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={require('../../../assets/images/certificates/ACP-JCA.png')}
                alt="Atlassian Certified Jira Administrator for Cloud"
                className="m-12"
                width={97}
                height={97}
              />
            </a>

            <a
              href="https://university.atlassian.com/student/activity/840992-acp-620-managing-jira-cloud-projects-certification"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={require('../../../assets/images/certificates/ACP-MJCP.png')}
                alt="Atlassian Certified Managing Jira Cloud Projects"
                className="m-12"
                width={97}
                height={97}
              />
            </a>

            <div className="w-100 d-none d-md-block" />

            <a href="https://scaledagile.com/training/safe-scrum-master/" target="_blank" rel="noopener noreferrer">
              <Image
                src={require('../../../assets/images/certificates/SSM-SAFe4.png')}
                alt="SAFe® 4 Scrum Master Certification"
                className="m-12"
                width={97}
                height={97}
              />
            </a>

            <a href="https://en.wikipedia.org/wiki/ITIL" target="_blank" rel="noopener noreferrer">
              <Image
                src={require('../../../assets/images/certificates/ITIL.png')}
                alt="ITIL® Foundation"
                className="m-12"
                width={97}
                height={97}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

Certificates.defaultProps = defaultProps;

export default Certificates;
