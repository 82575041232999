import React from 'react';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import AboutUs from '../../components/sections/about-us/AboutUs';
import { graphql } from 'gatsby';
import { AllJsonData } from '../../types/graphql';
import { Partner } from '../../types/partner';
import Partners from '../../components/sections/partners/Partners';
import Clients from '../../components/sections/clients/Clients';
import Certificates from '../../components/sections/certificates/Certificates';

interface AboutUsPageProps extends AllJsonData<Partner, { partners: [] }> {}

const AboutUsPage: React.FC<AboutUsPageProps> = ({ data }) => {
  const partners = data?.partners.nodes || [];

  return (
    <LayoutDefault>
      <PageMetaData title="About Us" />
      <AboutUs />
      <Certificates topDivider />
      <Clients topDivider title="Companies that trust us" />
      <Partners
        partners={partners as Partner[]}
        title="Partners"
        description="In our pursuit of delivering superior services, we collaborate with strategic partners to offer integrated solutions for our clients. Together, we amplify our strengths and capabilities!"
        invertMobile
        topDivider
        imageFill
      />
    </LayoutDefault>
  );
};

export default AboutUsPage;

export const query = graphql`
  query AboutUsPage {
    partners: allJson(filter: { contentName: { eq: "partners" } }, sort: { fields: order }) {
      nodes {
        title
        description
        image {
          publicURL
        }
        url
      }
    }
  }
`;
